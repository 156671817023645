<template>
  <div>
    <div class="newsList_bg">
      <img src="../../static/image/mobile/m_news.png" />
    </div>
    <van-tabs
      v-model="tabIndex"
      @click="tabClick"
      title-active-color="#9E1307"
      color="#565759"
    >
      <van-tab title="信息披露" name="13"></van-tab>
      <van-tab title="新闻资讯" name="12"></van-tab>
      <van-tab title="风险提示" name="14"></van-tab>
      <van-tab title="购销指南" name="15"></van-tab>
    </van-tabs>
    <div class="news_list">
      <div
        v-for="item in newsList"
        :key="item.id"
        class="news_list_item"
        @click="toDetails(item)"
      >
        <div class="news_list_item_title">· {{ item.title }}</div>
        <div class="news_list_item_year">{{ item.year }}-{{ item.day }}</div>
      </div>
    </div>
    <div class="newsList_pagination">
      <el-pagination
        layout="prev, pager, next"
        :page-size="5"
        :total="query.total"
        @current-change="handleCurrentChange"
        prev-text="上一页"
        next-text="下一页"
        :pager-count="5"
      ></el-pagination>
    </div>
    <div class="news_recommend" v-if="recommendList.length > 0">
      <div class="news_recommend_bg">
        <div class="news_recommend_name">推荐文章</div>
        <div class="news_recommend_title">
          {{ recommendList[0].title }}
        </div>
        <div class="news_recommend_scroll">
          <div v-show="recommendPage != 0" @click="otherPage(-1)">上一篇</div>
          <div
            v-show="recommendPage + 1 != recommendTotal"
            @click="otherPage(1)"
          >
            下一篇
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "newsList",
  data() {
    return {
      tabList: ["信息披露", "新闻资讯", "风险提示", "购销指南"],
      tabId: ["13", "12", "14", "15"],
      tabIndex: 13,
      recommendList: [],
      recommendPage: 0,
      recommendTotal: 0,
      newsList: [],
      query: {
        page: 0,
        size: 5,
        total: 0,
      },
    };
  },
  mounted() {
    window.scroll(0, 0);
    if (this.$route.query.index) {
      this.tabIndex = this.$route.query.index;
    }
    this.getList(this.tabIndex); // this.tabId[this.tabIndex] 信息纰漏
    this.getList(18); // 18 文章推荐
  },
  methods: {
    toDetails(item) {
      this.$router.push({
        path: "/mnewsListDetail",
        query: { id: item.id },
      });
    },
    tabClick(name) {
      this.query.page = 0;
      this.tabIndex = name;
      this.getList(name); // this.tabId[index]
    },
    getList(categoryId) {
      const page = categoryId == 18 ? this.recommendPage : this.query.page;
      const size = categoryId == 18 ? 1 : 5;
      axios
        .get(
          `https://admin.zoneyu.cn/api/admin/posts?status=PUBLISHED&page=${page}&size=${size}&categoryId=${categoryId}`,
          {
            headers: {
              "Admin-Authorization": localStorage.getItem("token"),
            },
          }
        )
        .then(async (res) => {
          const content = res.data.data.content;
          content.forEach((item) => {
            const updateTime = new Date(item.createTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.title.length > 30
                ? item.title.substring(0, 30) + "..."
                : item.title;
          });
          if (categoryId == 18) {
            this.recommendList = content;
            this.recommendTotal = res.data.data.total;
          } else {
            this.newsList = content;
            this.query.total = res.data.data.total;
          }
        });
    },
    handleCurrentChange(val) {
      this.query.page = val - 1;
      this.getList(this.tabIndex);
    },
    otherPage(val) {
      this.recommendPage = this.recommendPage + val;
      this.getList(18);
    },
  },
};
</script>
<style scoped lang="scss">
.newsList_bg {
  img {
    width: 100%;
  }
}
.news_list {
  padding: 12px;
  .news_list_item {
    border-bottom: 1px dashed #e5e5e5;
    margin-top: 15px;
    .news_list_item_title {
      font-size: 14px;
      font-weight: 300;
      color: #1d1b19;
      line-height: 25px;
      letter-spacing: 1px;
    }
    .news_list_item_year {
      margin-top: 4px;
      margin-bottom: 15px;
      font-size: 12px;
      font-weight: 300;
      color: #abaeb0;
      line-height: 16px;
      padding-left: 12px;
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #9e1307 !important;
  height: 1px;
  width: 56px;
}

.newsList_pagination {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 25px;
}
.news_recommend {
  padding: 30px 12px;
  .news_recommend_bg {
    background-color: #f7f7f7;
    padding: 12px;
    .news_recommend_name {
      font-size: 14px;
      font-weight: 600;
      color: #1d1b19;
      line-height: 25px;
      letter-spacing: 1px;
    }
    .news_recommend_title {
      font-size: 14px;
      color: #565759;
      line-height: 25px;
      letter-spacing: 1px;
      margin-top: 4px;
      padding-bottom: 12px;
      border-bottom: 1px dashed #e5e5e5;
      white-space: nowrap; /* 防止文本换行 */
      overflow: hidden; /* 控制文本溢出时隐藏超出部分 */
      text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
    }
    .news_recommend_scroll {
      margin-top: 12px;
      font-size: 12px;
      font-weight: 300;
      color: #9e1307;
      line-height: 25px;
      display: flex;
      justify-content: space-between;
    }
  }
}
::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #9e1307;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}
::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}
::v-deep .el-pager li.active + li {
  border: 1px solid #e5e5e5;
}
</style>
